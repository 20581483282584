/********************************************************************
  New Class Instance 
  LTS.Mobile
  Return false
  Paramaters false 
  Handles Mobile fucntionality : 
  1 ) Switches out all background images to "480x750" images. 
*********************************************************************/
(function () {
  
  'use strict'; 
  
  var Mobile = LTS.Mobile = (function ($) {
      
    function Mobile(){

     var scope = this;
     this.width = $(window).width();
     this.mobileWidth = 767;
      
     if(this.width < this.mobileWidth){ 
        this.SwitchBackgroundImages('mobile');
     }else{
       this.SwitchBackgroundImages('desktop');
     }

     $(window).off('resized');
     $(window).resize(function() {
        if(this.resizeTO) {
          clearTimeout(this.resizeTO);
        }
        this.resizeTO = setTimeout(function() {
            $(this).trigger('resized');
        }, 500);
     });

     $(window).bind('resized', function() {
          scope.width = $(window).width();
          if(scope.width < scope.mobileWidth){
            scope.SwitchBackgroundImages('mobile');
          }
          if(scope.width > scope.mobileWidth){
            scope.SwitchBackgroundImages('desktop');
          }
      });
 

    }
  
    Mobile.prototype = {

      // switch out background images for mobile 
      SwitchBackgroundImages : function  (type) {
        console.log( "Background switcher:: " + type);
          $('.page__header').each(function () {
           var bg = $(this).data(type);
           if(bg){$(this).css({'background-image':'url('+bg+')'});}
          });
      },

      Detect : function () {
         var bool = false; 
         if(this.width < this.mobileWidth){ 
           bool = true;
         }
         return bool;
      }

    };
    
   return Mobile;  
  
})(jQuery);
}).call(this);