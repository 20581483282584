/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// MAIN NAMESPACE  
var LTS = this.LTS = {};
LTS.version = '1.0.0';


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // 
        $('body').imagesLoaded({ background: '.image.background' }, function() {
          // images have loaded
          if(!$('body').is('.loaded')){
            $('body').addClass("loaded");

             var mobile = new LTS.Mobile();

            $('.page__loader').fadeOut(600, function(){
              $(this).remove();
            });
          }
        });

 
        // Gateway Form Enter 
        $('.block__check__box').on('click',function(){
          $(this).toggleClass('checked');
        });

        // Gateway submit 
        if (document.cookie.indexOf("gateway") < 0) {
           $('.block--modal').fadeIn();
           $('body').addClass( 'overflow');
        } else {
           $('.block--modal').fadeOut();
           $('body').removeClass( 'overflow');
        }
        $('.gateway--submit').on('click',function() {
            var investorType = $('.block--investor--type select').val();
            var countryType = $('.block--country--type select').val();

            // if(countryType === 'U.S. Resident'){
            //     $('.block__form').fadeOut();
            //     $('.block__error_message').fadeIn();
            //   return;
            // }

            if(investorType === 'None of the above' || investorType === 'Private Investors' || investorType === 'Corporate Investors' || investorType === 'Public Investors'){
                $('.block__form').fadeOut();
                $('.block__error_message').fadeIn();
              return;
            }

            if(
                investorType === "Stakeholder / Service Providers" || 
                investorType === "Existing Qualified Investors"
                
              ){
                if($('.block__check__box').hasClass('checked')){
                  
                  $('.block--modal').fadeOut();
                  $('#signin-modal').hide();
                  $('body').removeClass( 'overflow');
                  var expiry_mp = new Date();
                  expiry_mp.setTime(expiry_mp.getTime() + 0.5 * 3600 * 1000);
                 // expiry_mp.setTime(expiry_mp.getTime()+(10 * 365 * 24 * 60 * 60));
                  document.cookie = "gateway=yes; path=/; expires=" + expiry_mp.toGMTString();
                 
 
                } else {
                  alert('Please confirm that you have read and understand the important information.');
                }
            } else {
              
              $('.block--modal').hide();
              $('#signin-modal').show();
              $('.block--signin .form-control').val('');
              $('.block__error_message').hide();
              $('body').addClass( 'overflow');
              $('.header__mobile__nav').slideUp();
              $('.header__burger__btn').removeClass('nav--open');
              $('#signin-modal .block__medium_title h2').html("Login");
              $('.investor--close').hide();
               
              //
            }
        });

        $('.block__error_message .btn').on('click',function() {
              $('.block__form').fadeIn();
              $('.block__error_message').hide();
              $('.block__check__box').removeClass('checked');
              $('.block__select__box').prop('selectedIndex',0);
        });

        // 
        $('.header__nav__investorlink,.header__nav__investorlink__mobile').on('click',function(){
          $('.block--modal').hide();
          $('#signin-modal').fadeIn();
          $('#signin-modal .block__medium_title h2').html('Investor Login');
          $('.block__close__button.gateway--close').hide();
          $('.block__close__button.investor--close').show();
          $('.block--signin .form-control').val('');
          $('.block__error_message').hide();
           $('body').addClass( 'overflow');
          $('.header__mobile__nav').slideUp();
          $('.header__burger__btn').removeClass('nav--open');

        });
        $('.signin--button').on('click',function(){

          var pass = $("#pass").val();
          var usr = $("#usr").val();
          if(usr === "ltsinvestment" && pass === "RHguVcgwRvuoiWLK"){
            $('#signin-modal').fadeOut();
            $('body').removeClass( 'overflow');
            $('.block--modal').hide();
             var expiry_mp = new Date();
            expiry_mp.setTime(expiry_mp.getTime() + 0.5 * 3600 * 1000);
           // expiry_mp.setTime(expiry_mp.getTime()+(10 * 365 * 24 * 60 * 60));
            document.cookie = "gateway=yes; path=/; expires=" + expiry_mp.toGMTString();
          } else {
             $('.block__error_message').fadeIn();
          }

         
        });
        $('.block__close__button.investor--close').on('click',function(){
          $('.block--modal').hide();
          $('#signin-modal').fadeOut();
          $('body').removeClass( 'overflow');
        });

        $('.block__close__button.gateway--close').on('click',function(){
          $('.block--modal').show();
          $('#signin-modal').hide();
          $('.block__form').fadeOut();
          $('.block__error_message').fadeIn();
        });
     

        // COOKIE BAR 
        if (document.cookie.indexOf("visited") < 0) {
            $('.footer__cookie_bar').show();
        } else {
          $('.footer').removeClass('cookie--bar--active');
        }

        $('.footer__cookie_bar .btn').click(function(e) {
            e.preventDefault();
            $('.footer__cookie_bar').animate({'bottom':'-300px'});
            $('.footer').removeClass('cookie--bar--active');
        
            expiry = new Date();
            //expiry.setTime(expiry.getTime()+(10 * 365 * 24 * 60 * 60));
            expiry.setTime(expiry.getTime() + 0.5 * 3600 * 1000);
            document.cookie = "visited=yes; path=/; expires=" + expiry.toGMTString();
        });


        // MOBILE NAV
        $('.header__burger__btn').on('click',function(){
         
          if($('.header__burger__btn').hasClass('nav--open')){
            $('body').removeClass( 'overflow');
            $('.header__mobile__nav').slideUp();
            $('.header__burger__btn').removeClass('nav--open');
          } else {
            $('body').addClass( 'overflow');
            $('.header__mobile__nav').slideDown();
            $('.header__burger__btn').addClass('nav--open');
          }

        }); 


        // Grey Hover 
        $('.hover__grey').on('mouseover', function () {
          var tar = $(this).find('img').data('logo');
          $(this).find('img').attr('src',tar);
        });

        $('.hover__grey').on('mouseleave', function () {
          var tar = $(this).find('img').data('grey');
          $(this).find('img').attr('src',tar);
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        // Main nav 
        $('.header__nav__item').on('click',function(e){
          e.preventDefault();
          $('body').removeClass( 'overflow');
          $('.header__mobile__nav').slideUp();
          $('.header__burger__btn').removeClass('nav--open');
          $('.header__nav__item').removeClass('current_page_item');
          $(this).addClass('current_page_item');
          var target = $(this).find('a').attr('href');
          var elementOffset = $('.'+target).offset().top;
          $("html,body").animate({ scrollTop: elementOffset - 150 }, 500);
        });


          var aChildren = $(".header__nav__list").children(); // find the a children of the list items
          var aArray = []; // create the empty aArray
          var bArray = []; // create the empty aArray
          for (var i=0; i < aChildren.length; i++) {    
              var aChild = aChildren[i];
              var ahref = $(aChild).find('a').attr('href');
              aArray.push('.'+ahref);
              bArray.push(ahref);
          }  

        
        $(window).scroll(function(){
            var windowPos = $(window).scrollTop() + 400;  
            var windowHeight = $(window).height();  
            var docHeight = $(document).height();

            for (var i=0; i < aArray.length; i++) {
                var theID = aArray[i];
                var theClass = bArray[i];

                if(typeof $(theID).offset().top !== undefined){
                
                var divPos = $(theID).offset().top; 
                var divHeight = $(theID).height();  
                if(divPos === 0){  divHeight = 690;  }

               // console.log(windowPos + " :: "  + divPos + " :: " + divHeight + " :: " + theID);
                if (windowPos >= divPos && windowPos < (divPos + divHeight) ) {
                   $("a[href='" + theClass + "']").parent().addClass("current_page_item");
                }else{
                  $("a[href='" + theClass + "']").parent().removeClass("current_page_item");
                }
              }
            }

        });


          google.charts.load('current', {'packages':['corechart']});
       google.charts.setOnLoadCallback(drawChart);

        function drawChart() {

          var data = new google.visualization.DataTable(); 
          data.addColumn('string', 'Title'); 
          data.addColumn('number', 'Percent');   
          var colors = [];
          $('.blk--b2').each(function(){
            var color = $(this).data('color');
            var title = $(this).data('title');
            var percent = parseInt($(this).data('percent'));
             data.addRow([title,percent]);  
             colors.push(color);
             
          });
 
          var options = {
            title: '',
            legend: 'none',
            pieSliceText: 'none',
            colors:colors,
            backgroundColor: 'transparent'
          };

          var chart = new google.visualization.PieChart(document.getElementById('piechart'));
          chart.draw(data, options);

          drawChart2();
        }

        function drawChart2() {

          var data = new google.visualization.DataTable(); 
          data.addColumn('string', 'Title'); 
          data.addColumn('number', 'Percent');   
          var colors = [];
          $('.blk--b3').each(function(){
            var color = $(this).data('color');
            var title = $(this).data('title');
            var percent = parseInt($(this).data('percent'));
             data.addRow([title,percent]);  
             colors.push(color);
             
          });
 
          var options = {
            title: '',
            legend: 'none',
            pieSliceText: 'none',
            colors:colors,
            backgroundColor: 'transparent'
          };

          var chart = new google.visualization.PieChart(document.getElementById('piechart__2'));
          chart.draw(data, options);
        }



      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
